import { HeaderViewType, updateHeader } from '@soluto-private/mx-app-header';

export const updateMxHeader = (
    isMx: boolean,
    view: HeaderViewType = 'service-view',
    title = 'WiFi Management',
    backTitle?: string,
    onBack?: () => void,
) => {
    if (!isMx) return;

    updateHeader({
        // always required.
        // one of: main-view, service-view, external-view, any other value hides header
        view,
        // required by service and external-view. title presented on scroll down
        title,
        // required by service and external-view. back title presented when user is on top of the page
        backTitle,
        // optional. overrides back button behavior. defaults to window.history.back()
        onBack,
    });
};
