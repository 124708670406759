import styled from '@emotion/styled';
import { MultiPointEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import { FilledButton } from '@home-mgmt-shared/common-ui';
import React, { ReactNode } from 'react';

const Header = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 50px;
`;
const Image = styled.div`
    display: flex;
    font-size: 1rem;
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
`;
const ConfirmationText = styled.div`
    font-size: 1rem;
    font-weight: bold;
    font-family: ${(props) => props.theme.font?.bold};
    margin-top: 50px;
    margin-bottom: 10px;
`;
const SpeedTestCounter = styled.div`
    font-size: 0.875rem;
    margin-bottom: 15px;
    font-family: ${(props) => props.theme.font?.bold};
    font-weight: bold;
    align-self: flex-start;
    margin-top: 20px;
`;

const RoomIntroPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    max-width: 23rem;
`;

interface RoomIntroPageProps {
    speedTestCounter: string;
    header: ReactNode;
    image: ReactNode;
    confirmationText: string;
    startSpeedTest: () => void;
    buttonText: string;
    roomName: string;
}

export const RoomIntroPage = ({
    speedTestCounter,
    header,
    image,
    confirmationText,
    startSpeedTest,
    buttonText,
    roomName,
}: RoomIntroPageProps) => {
    usePageAnalytics(roomName === 'Router' ? MultiPointEvents.RouterPrescanPage : MultiPointEvents.RoomPrescanPage, {
        roomName,
    });
    return (
        <RoomIntroPageContainer>
            <SpeedTestCounter>{speedTestCounter}</SpeedTestCounter>
            <Header>{header}</Header>
            <Image>{image}</Image>
            <ConfirmationText>{confirmationText}</ConfirmationText>
            <FilledButton
                data-test-cy={`signal-start-${roomName}-scan`}
                analyticEventName="StartSpeedTest"
                onClick={startSpeedTest}
                analyticsExtras={{ roomName }}
            >
                {buttonText}
            </FilledButton>
        </RoomIntroPageContainer>
    );
};
