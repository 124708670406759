import styled from '@emotion/styled';
import { BinaryFeedback } from '@home-mgmt-shared/binary-feedback';
import {
  ClickableButton,
  IStreamingQualityGuideline,
} from '@home-mgmt-shared/common-ui';
import { updateMxHeader } from '@home-mgmt-shared/mx-header';
import {
  OverviewConfig,
  OverviewPageState,
  OverviewView,
} from '@home-mgmt-shared/network-overview-page';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback, useEffect, useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { BasePage } from './BasePage';

const DEFAULT_STREAMING_GUIDELINES = [
  { capability: 'Email', downloadReq: 1, uploadReq: 0 },
  { capability: 'Browse the web', downloadReq: 3, uploadReq: 0 },
  { capability: 'Game online', downloadReq: 5, uploadReq: 0 },
  { capability: 'Video chat', downloadReq: 1, uploadReq: 1 },
  { capability: 'Video conference', downloadReq: 5, uploadReq: 2 },
  {
    capability: 'Stream HD video on a single device',
    downloadReq: 5,
    uploadReq: 0,
  },
  {
    capability: 'Stream HD video on multiple devices',
    downloadReq: 10,
    uploadReq: 0,
  },
  {
    capability: 'Stream 4k video on a single device',
    downloadReq: 25,
    uploadReq: 0,
  },
];

const clickEvent = (eventName: string, props: Record<string, unknown>) => {
  analytics.dispatch(eventName, NSEventType.Click, props);
};

interface BackButtonConfig {
  enabledPages: OverviewPageState[];
}

const backButtonConfig: BackButtonConfig = {
  enabledPages: [],
};

const BinaryFeedbackWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  height: 100%;
  width: 5.75rem;
  max-width: 4.75rem;
  max-height: 2rem;
  margin-right: 1rem;
`;

const config: OverviewConfig = {
  BinaryFeedback: (
    <BinaryFeedbackWrapper>
      <BinaryFeedback
        greetingText="Was this speed chart helpful?"
        farewellText="Thanks for your feedback!"
        positiveFeedbackIcon={
          <ButtonWrapper>
            <ClickableButton label="Yes" />
          </ButtonWrapper>
        }
        negativeFeedbackIcon={
          <ButtonWrapper>
            <ClickableButton label="No" />
          </ButtonWrapper>
        }
        analyticsCb={clickEvent}
      />
    </BinaryFeedbackWrapper>
  ),
};

export const OverviewPage = () => {
  const [back, setBack] = useState<boolean>(false);
  const [showBack, setShowBack] = useState<boolean>(false);
  useEffect(() => {
    updateMxHeader(true, 'service-view', 'WiFi Management');
  }, []);

  const streamingGuidelines: IStreamingQualityGuideline[] = useTweekValue(
    'network_scan/capabilities/streaming_capabilities',
    DEFAULT_STREAMING_GUIDELINES
  );

  const onPageChange = useCallback((page: OverviewPageState) => {
    if (backButtonConfig.enabledPages.includes(page)) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }
  }, []);
  const onPreviousPage = useCallback(() => {
    setBack(!back);
  }, [back]);

  return (
    <BasePage backButtonCallback={showBack ? onPreviousPage : undefined}>
      <OverviewView
        onPageChange={onPageChange}
        backWasClicked={back}
        overviewConfig={config}
        streamingGuidelines={streamingGuidelines}
      />
    </BasePage>
  );
};

export default OverviewPage;
