import styled from '@emotion/styled';
import { BackButton } from '@home-mgmt-shared/common-ui';
import AttFooter from '@soluto-asurion/one-service-footer-react';
import AttHeader from '@soluto-asurion/one-service-header-react';
import React, { ReactNode, useEffect } from 'react';
import { updateMxHeader } from '@home-mgmt-shared/mx-header';

interface BasePageProps {
  className?: string;
  children?: ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  backButtonCallback?: () => void;
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: ${(props) => props.theme.font?.type};
  font-size: 16px;
  background: #fff;

  @supports (-webkit-touch-callout: none) {
    height: 100%;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

export const BasePage = ({
  className,
  children,
  hideHeader = IS_MX_APP,
  hideFooter = IS_MX_APP,
  backButtonCallback,
}: BasePageProps) => {
  const handleUserSignInStatus = () => {};
  useEffect(() => {
    updateMxHeader(IS_MX_APP, 'service-view', 'WiFi Management');
  }, []);
  return (
    <PageContainer className={className ?? ''}>
      {!hideHeader && (
        <AttHeader
          partner="att"
          userStatus={handleUserSignInStatus}
          isProduction={process.env.NODE_ENV !== 'development'}
          appName="streaming-support"
        />
      )}
      {backButtonCallback !== undefined && (
        <BackButton backButtonCallback={backButtonCallback} />
      )}
      <ContentContainer role="main">
        {children} {!hideFooter && <AttFooter />}
      </ContentContainer>
    </PageContainer>
  );
};
