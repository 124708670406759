import React from 'react';
import { Route, RouteProps, Switch, withRouter } from 'react-router-dom';
import { BandwidthCalculatorPage, MultiScanPage, OverviewPage } from '../pages';
import { withThemeProviderWrapper } from '../providers';
import {
  appScanTheme,
  bandwidthTheme,
  overviewTheme,
  webScanTheme,
} from '../themes';
import AttDashboard from './ATT/App';
import { ScanPage } from './ATT/ScanPage';

let AuthenticatedMXRoute: React.FC<RouteProps>;
if (IS_MX_APP) {
  // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires
  AuthenticatedMXRoute = require('@home-mgmt-shared/auth').AuthenticatedMXRoute;
}

const Routes = () => (
  <Switch>
    <Route
      path="/scan"
      component={withThemeProviderWrapper(ScanPage, webScanTheme)}
    />
    <Route
      path="/signal"
      component={withThemeProviderWrapper(MultiScanPage, webScanTheme)}
    />
    <Route
      path="/bandwidth"
      component={withThemeProviderWrapper(
        BandwidthCalculatorPage,
        bandwidthTheme
      )}
    />

    {!IS_MX_APP ? (
      <>
        <Route
          exact
          path="/"
          component={withThemeProviderWrapper(AttDashboard, appScanTheme)}
        />
        <Route
          exact
          path="/streaming-support"
          component={withThemeProviderWrapper(AttDashboard, appScanTheme)}
        />
        <Route
          path="/streaming-support/scan"
          component={withThemeProviderWrapper(ScanPage, webScanTheme)}
        />
        <Route
          path="/streaming-support/signal"
          component={withThemeProviderWrapper(MultiScanPage, webScanTheme)}
        />
        <Route
          path="/streaming-support/bandwidth"
          component={withThemeProviderWrapper(
            BandwidthCalculatorPage,
            bandwidthTheme
          )}
        />
      </>
    ) : (
      <>
        <AuthenticatedMXRoute
          exact
          path="/overview"
          component={withThemeProviderWrapper(OverviewPage, overviewTheme)}
        />
        <AuthenticatedMXRoute
          exact
          path="/"
          component={withThemeProviderWrapper(OverviewPage, overviewTheme)}
        />
      </>
    )}
  </Switch>
);

export default withRouter(Routes);
