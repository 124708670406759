import styled from '@emotion/styled';
import { MultiPointEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import { Clapping, FilledButton } from '@home-mgmt-shared/common-ui';
import React, { useState } from 'react';
import { MultiPointScanState } from './models';
import { ScanningRouter } from './ScanningRouter';
import { determineSpeed } from './utils';

const Header = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
`;

const Subtext = styled.div`
    font-size: 1rem;
    font-family: ${(props) => props.theme.font?.type};
    margin-bottom: 70px;
    width: 100%;
    color: ${(props) => props.theme.text?.subText};
`;
const SpeedTestCounter = styled.div`
    font-size: 0.875rem;
    margin-bottom: 15px;
    font-family: ${(props) => props.theme.font?.bold};
    font-weight: bold;
    align-self: flex-start;
`;

const RoomIntroPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 23rem;
    margin-top: 1rem;
    align-self: center;
    width: 100%;
    height: 100%;
    align-items: center;
`;

const CheckingSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;

const CheckingMsg = styled.div`
    display: flex;
    font-weight: bold;
    font-family: ${(props) => props.theme.font?.bold};
    width: 100%;
    justify-content: center;
`;

interface RoomScanningPageProps {
    speedTestCounter: string;
    roomName: string;
    scanState: MultiPointScanState;
    downloadSpeed: number;
    onTestComplete?: () => void;
    minimumSpeed?: number;
}

interface SpeedProgress {
    progress: number;
}

const ProgressGray = styled.div`
    background-color: #e6e6eb;
    width: 100%;
    height: 8px;
    border-radius: 10px;
`;

const ProgressFilled = styled.div`
    background-color: ${(props) => props.theme.components?.multiScanProgress?.progressFillColor || '#8223d2'};
    transition: width 10s linear;
    width: ${(props: SpeedProgress) => props.progress}%;
    height: 8px;
    border-radius: 10px;
`;

const ProgressContainer = styled.div`
    display: flex;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    margin: 25px 0px 60px 0px;
`;

const DownloadSpeed = styled.div`
    display: flex;
    font-size: 3rem;
`;

const MbpsLabel = styled.div`
    font-size: 0.875rem;
    color: #000000;
    margin-bottom: 1rem;
`;

const DownloadSpeedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

const ClappingWrapper = styled.div`
    display: flex;
    margin-bottom: 2rem;
    margin-top: 2rem;
`;

export const RoomScanningPage = ({
    speedTestCounter,
    roomName,
    scanState,
    downloadSpeed,
    onTestComplete,
    minimumSpeed,
}: RoomScanningPageProps) => {
    const [progress, setProgress] = useState<number>(0);

    const inProgress = scanState === MultiPointScanState.STARTED;
    const initializing = scanState === MultiPointScanState.INITIALIZING;
    usePageAnalytics(MultiPointEvents.ScanningPage, { roomName, inProgress });

    setTimeout(() => {
        setProgress(100);
    }, 100);

    const headerMsg =
        scanState === MultiPointScanState.FINISHED
            ? `${roomName} speed test complete!`
            : `${roomName} speed test in progress...`;

    return (
        <RoomIntroPageContainer>
            <SpeedTestCounter>{speedTestCounter}</SpeedTestCounter>
            <Header>{headerMsg}</Header>
            {inProgress && <Subtext>Try not to move! This should take 10 seconds.</Subtext>}
            {(inProgress || initializing) && (
                <>
                    <ScanningRouter />
                    <CheckingSection>
                        <CheckingMsg>{roomName} download speed</CheckingMsg>
                    </CheckingSection>
                    <ProgressContainer>
                        <ProgressGray>
                            <ProgressFilled progress={progress} />
                        </ProgressGray>
                    </ProgressContainer>
                </>
            )}

            {!inProgress && !initializing && (
                <>
                    <ClappingWrapper>
                        <Clapping />
                    </ClappingWrapper>
                    <CheckingSection>
                        <CheckingMsg>{roomName} download speed</CheckingMsg>
                    </CheckingSection>

                    <DownloadSpeedContainer>
                        <DownloadSpeed>{determineSpeed(downloadSpeed, minimumSpeed)}</DownloadSpeed>
                        <MbpsLabel>Mbps</MbpsLabel>
                    </DownloadSpeedContainer>
                    <FilledButton
                        data-test-cy="complete_signal_test"
                        analyticEventName="TestCompleteNext"
                        onClick={onTestComplete}
                        analyticsExtras={{ roomName }}
                    >
                        Next
                    </FilledButton>
                </>
            )}
        </RoomIntroPageContainer>
    );
};
